import React from "react";
import { Helmet } from "react-helmet";

interface MetaItem {
  name: string;
  content: string;
}
interface Props {
  pathName?: string;
  name?: string;
  slogan?: string;
  thumbnail?: string;
  title?: string;
  description?: string;
  author?: string;
  keywords?: any;
  socials?: string[];
  lang?: string;
  meta?: MetaItem[];
  noindex?: boolean;
  hideAppStore?: boolean;
}
const SEOReactHelmet: React.FunctionComponent<Props> = ({
  pathName = typeof window !== "undefined" ? location.pathname.replace("/", "").replace(/\/$/, "") : "",
  name,
  slogan,
  thumbnail,
  title,
  description,
  author,
  keywords,
  socials,
  lang = "en",
  noindex,
  hideAppStore,
}) => {
  const originURL = process.env.GATSBY_WEBSITE_URL
    ? process.env.GATSBY_WEBSITE_URL
    : "https://www.dietdoctor.com";

  const seo = {
    originURL: originURL,
    url:
      "en" === lang
        ? `${originURL}/${pathName?.length > 0 ? `${pathName}` : ""}`
        : `${originURL}/${lang}/${pathName.length > 0 ? `${pathName}` : ""}`,
    name: name || "Diet Doctor",
    slogan: slogan || "Making Low Carb and Keto Simple",
    title: title || "Diet Doctor - Making Low Carb and Keto Simple",
    thumbnail: thumbnail ? `${originURL}/${thumbnail}` : `${originURL}/images/thumbnails/dd-thumbnail.jpg`, // 1200x630
    description:
      description ||
      "How do you start a keto or low carb diet? We have delicious recipes, amazing meal plans, the best keto videos, and a supportive low carb community to help dramatically improve your health. Welcome to Diet Doctor, where we make low carb simple.",
    author: author || "@DietDoctor",
    keywords: keywords || "keto, ketogenic, low-carb, high-protein, high-satiety, diet",
    socials: socials || [
      "https://www.facebook.com/thedietdoctor",
      "https://www.instagram.com/diet_doctor",
      "https://twitter.com/DietDoctor",
      "https://www.pinterest.com/thedietdoctor",
      "https://www.youtube.com/c/DietDoctorVideo",
    ],
    language: lang || "en",
    slug: "en" === lang ? `/${pathName}` : `/${lang}/${pathName}`,
  };

  return (
    <Helmet defer={false}>
      {/* General */}
      <link id="canonical" rel="canonical" href={seo.url} />
      <meta id="language" name="language" content={seo.language} />
      <title id="title">{seo.title}</title>
      {noindex && <meta id="robots-index" name="robots" content="noindex" />}
      {!noindex && <meta id="robots-preview" name="robots" content="max-image-preview:large" />}
      <meta id="description" name="description" content={seo.description} />
      <meta id="url" name="url" content={seo.url} />
      <meta id="keywords" name="keywords" content={seo.keywords} />
      <meta id="author" name="author" content={seo.author} />
      <meta id="image" name="image" content={seo.thumbnail} />

      {/* iOS Appstore banner */}
      {!hideAppStore && <meta name="apple-itunes-app" content="app-id=1440002708" />}

      {/* OpenGraph tags */}
      <meta id="og:site_name" name="og:site_name" content={seo.title} />
      <meta id="og:locale" name="og:locale" content={seo.language} />
      <meta id="og:url" name="og:url" content={seo.url} />
      <meta id="og:title" name="og:title" content={seo.title} />
      <meta id="og:description" name="og:description" content={seo.description} />
      <meta id="og:image" name="og:image" content={seo.thumbnail} />
      <meta id="og:image:secure_url" name="og:image:secure_url" content={seo.thumbnail} />
      <meta id="og:image:type" name="og:image:type" content={author ? "article" : "website"} />
      {author && seo.author && <meta id="article:publisher" name="article:publisher" content={seo.author} />}
      <meta id="fb:app_id" name="fb:app_id" content="204157646290083" />

      {/* Twitter Card tags */}
      <meta id="twitter:title" name="twitter:title" content={seo.title} />
      <meta id="twitter:description" name="twitter:description" content={seo.description} />
      <meta id="twitter:image" name="twitter:image" content={seo.thumbnail} />
      <meta id="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta id="twitter:creator" name="twitter:creator" content={seo.author} />
      <meta id="twitter:site" name="twitter:site" content="@DietDoctor" />

      {/* Schema.org tags */}
      <script id="schema-tags" type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@graph": [
            // Organization Type
            {
              "@type": "Organization",
              "@id": `${seo.originURL}/#organization`,
              url: `${seo.originURL}`,
              name: `${seo.name}`,
              logo: `${seo.originURL}/dd-icon-512x512.png`,
              sameAs: [...seo.socials],
              foundingDate: "2007-12-23",
              knowsAbout: "Empowering people everywhere to dramatically improve their health",
              awards: "DI Gaselle",
              numberOfEmployees: {
                "@type": "QuantitativeValue",
                value: "60",
              },
              legalName: "Diet Doctor Sweden AB",
              vatID: "SE556777806201",
              address: {
                "@type": "PostalAddress",
                streetAddress: "Fleminggatan 7",
                postalCode: "112 26",
                addressRegion: "Stockholm",
                addressCountry: "Sweden",
              },
              founder: {
                "@type": "Person",
                name: "Andreas Eenfeldt",
              },
            },

            // Website Type
            {
              "@type": "WebSite",
              "@id": `${seo.originURL}/#website`,
              url: `${seo.originURL}`,
              name: `${seo.name}`,
              sameAs: [...seo.socials],
              author: {
                "@type": "Person",
                name: `@DietDoctor`,
              },
              potentialAction: {
                "@type": "SearchAction",
                target: {
                  "@type": "EntryPoint",
                  urlTemplate: `${seo.originURL}/?s={search_term_string}`,
                },
                "query-input": {
                  "@type": "PropertyValueSpecification",
                  valueRequired: "https://schema.org/True",
                  valueName: "search_term_string",
                },
              },
            },

            // MobileApplication Type iOS
            {
              "@type": "MobileApplication",
              applicationCategory: "Health",
              availableOnDevice: "iOS, iPhone, iPad",
              operatingSystem: "iOS",
              about: seo.slogan,
              downloadUrl: "https://apps.apple.com/app/id1440002708",
            },

            // MobileApplication Type Android
            {
              "@type": "MobileApplication",
              applicationCategory: "Health",
              availableOnDevice:
                "Android, Samsung, Huawei, Google, Xiaomi, OnePlus, Sony, Motorola, LG, Asus, Nokia",
              operatingSystem: "Android",
              about: seo.slogan,
              downloadUrl: "https://play.google.com/store/apps/details?id=com.dietdoctor.EatApp",
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export { SEOReactHelmet };
